@tailwind base;
@tailwind components;
@tailwind utilities;

/* google fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Open+Sans:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.about_item:hover {
  box-shadow: 0 0 40px 0 rgba(82, 119, 238, 0.4);
}

.about_item .icon {
  transition: all .3s ease-in-out;
}

.about_item:hover .icon {
  color: #0F52BA;
  background: #fff;
}